const Constants = require('vibemap-constants/dist/constants.js')
const cities = require('vibemap-constants/dist/cities.json')
const activities = require('vibemap-constants/dist/activityCategories.json')

const topLevelCategories = activities.activityCategories.filter(category => {
    const level = parseInt(category.level)
    if (level <= 2 && category.slug != 'all') {
        return true
    }
}).sort((a, b) => parseInt(b.details.msv) - parseInt(a.details.msv))

const initialState = {
    activity: "all",
    allCities: cities,
    // TODO: Constants is a fine fall back, but this should come from the API
    allCategories: topLevelCategories,
    allVibes: [],
    // TODO: Not the best place for this.
    clusterSize: 300, // meters
    currentLocation: {
        latitude: 0,
        longitude: 0,
        name: null,
        distance_changed: 0 },
    currentPage: 1,
    hasLocation: false,
    mainVibe: null,
    vibes: [],
    vibesets: [],
    days: "14",
    date_options: [
        { key: '1', text: 'Today', value: '1' },
        { key: '2', text: '2 days', value: '2' },
        { key: '3', text: '3 days', value: '3' },
        { key: '7', text: 'Week', value: '5' },
        { key: '14', text: '2 weeks', value: '14' }
    ],
    placeType: "both",
    ordering: "relevance",
    ordering_options: [ "relevance", "vibe", "distance", "rating", "hours" ],
    numTopPicks: 20,
    searchResults: null,
    searchTerm: "",
    selected_activity: topLevelCategories[0],
    seo : {
        title: 'Vibemap',
        description: 'Vibemap is a city discovery app that connects you with meaningful places, experiences, and people that match your vibe.',
        image: 'https://vibemap.com/static/2679a7f0a8fa05da5d837dec434133b7/eeaba/Home_page-1.jpg',
        url: 'https://vibemap.com/',
        keywords: '',
        canonical: null,
        latitude: null,
        longitude: null
    },
    tips: [
        'Have you ever stopped to smell the roses near Grand Avenue?',
        'Do you know where there are so many palms trees on Dolores?',
        'Have you been to the the world\'s smallest park in Portland?',
        'How many days would it take to visit every brewery in Portland?',
        'Have you ever listened to music the oldest house in Guadalajara?',
        'Have your every looked at the Bay from the tip of Candlestick Park?',
        'Spend time searching for the Portland Unipiper - a unicyclist dressed as Darth Vadar who rides playing a flaming bagpipe',
        'Relax at Portland’s newest beach, Poet’s Cove and watch the boats go by',
        'Portland has the second-largest copper repoussé statue, after the Statue of Liberty?',
        'The most photographed image in Seattle is the rotating Pink Elephant Car Wash sign on Battery Street at Denny Way.',
        'The name “Seattle” is an Anglicization of Si\'ahl.Named after the Chief Si’ahl of the Duwamish tribe',
        'Measured by people-carrying capacity, the Washington State Ferries are the world’s #1 most used ferry system'
    ],
    totalPages: 5
}

export const nav = (state = initialState, action) => {
    //console.log('SET_REDUX_STATE: ', action)

    switch (action.type) {
        case 'SET_ACTIVITY':
            let activity = action.activity
            if (action.activity === "any") action.activity = null

            const all_categories = topLevelCategories
            let selected_activity = all_categories.find(({ slug }) => slug === activity)

            if (selected_activity === undefined) selected_activity = Constants.main_categories[0]

            return {
                ...state,
                activity: activity,
                selected_activity: selected_activity
            }

        case 'SET_ALL_CATEGORIES':
            //console.log('SET_ALL_CATEGORIES: ', action.allCategories)
            return {
                ...state,
                allCategories: action.allCategories
            }

        case 'SET_ALL_CITIES':
            return {
                ...state,
                allCities: action.allCities
            }

        case 'SET_ALL_VIBES':
            return {
                ...state,
                allVibes: action.allVibes
            }

        case 'SET_CURRENT_LOCATION':
            action.location.latitude = parseFloat(action.location.latitude)
            action.location.longitude = parseFloat(action.location.longitude)

            return {
                ...state,
                currentLocation: action.location
            }

        case 'SET_HAS_LOCATION':
            //console.log('SET_HAS_LOCATION', action.hasLocation)

            return {
                ...state,
                hasLocation: action.hasLocation
            }

        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.page
            }

        case 'SET_DAYS':
            return {
                ...state,
                days: action.days
            }

        case 'SET_MAIN_VIBE':
            return {
                ...state,
                mainVibe: action.vibe
            }

        case 'SET_ORDERING':
            return {
                ...state,
                ordering: action.ordering
            }

        case 'SET_PAGE_SEO':
            return {
                ...state,
                seo: action.seo
            }

        case 'SET_PLACE_TYPE':
            return {
                ...state,
                placeType: action.placeType
            }

        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.results
            }

        case 'SET_SEARCH_TERM':
            console.log('SET_SEARCH_TERM: ', action.searchTerm)
            return {
                ...state,
                searchTerm: action.searchTerm
            }

        case 'SET_TOTAL_PAGES':
            return {
                ...state,
                totalPages: action.pages
            }

        case 'SET_VIBES':
            return {
                ...state,
                vibes: action.vibes
            }

        case 'SET_VIBESETS':
            return {
                ...state,
                vibesets: action.vibesets
            }

        default:
            return state
    }
}

export default nav;
