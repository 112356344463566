import ReactGA from 'react-ga';

// Match the event definition to a Redux action:
const gaMiddleware = store => next => action => {

  const state = store.getState()
  const location = state.nav.currentLocation
  const mainVibe = state.nav.mainVibe
  const vibes = state.nav.vibes

  const currentItem = state.places.currentItem

  if (action.type === 'SET_IS_BROWSER') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'app_opened',
      label: location.name
    })
  }

  if (action.type === 'SET_DETAILS_LOADING') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'place_opened',
      label: currentItem.name
    })
  }

  if (action.type === 'SET_DETAILS_LOADING') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'place_opened',
      label: currentItem.name
    })
  }

  if (action.type === 'SET_MAIN_VIBE') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'searched_vibeset',
      label: mainVibe
    })
  }

  if (action.type === 'SET_MESSAGE') {
    //console.log('Analytics for SET_MESSAGE ', action.message)

    // TODO: Add user_id
    if (action.message.id === 'vibe_added') {
      ReactGA.event({
        category: 'App',
        action: 'vibe_added',
        label: action.message.vibe + action.message.id
      })
    }
  }

  if (action.type === 'SET_VIBES') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'searched_vibes',
      label: vibes.join(', ')
    })
  }

  if (action.type === 'SET_ACTIVITY') {
    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'searched_activity',
      label: action.activity
    })
  }

  if (action.type === 'SET_SAVED_PLACES') {
    let saved = null
    if (action.savedPlaces.length > 0) saved = action.savedPlaces[action.savedPlaces.length - 1]

    // TODO: Add user_id
    ReactGA.event({
      category: 'App',
      action: 'place_saved',
      label: saved ? saved.name : saved
    })
  }

  if (action.type === 'SET_SEARCH_TERM') {
    ReactGA.event({
      category: 'App',
      action: 'searched_keyword',
      label: action.searchTerm
    })

  }


  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.pathname}${action.payload.search}`;
    trackPage(nextPage, store.getState());
  }

  /*
  if (eventTracking[action.type]) {
    trackEvent(eventTracking[action.type], store.getState());
  }
  */

  return next(action);
}

export const fireEvent = (id, event) => {

  if(document.getElementById(id) !== null) {
      let new_event = new Event(event, { bubbles: true, cancelable: false });

      document.getElementById(id).dispatchEvent(new_event);

      if (document.getElementById(id).fireEvent) {


          document.getElementById(id).fireEvent(event);
      } else {

          /*
          var evObj = document.createEvent('Events');
          evObj.initEvent(event, true, false);
          */
          let new_event = new Event(event, { bubbles: true, cancelable: false });

          document.getElementById(id).dispatchEvent(new_event);
      }
  }
}

export default gaMiddleware