import React from 'react';

import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component'

import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { unregister } from 'razzle-plugin-workbox/service-worker';
import routes from './pages/routes';

// Internationalization
// import i18n from './services/i18n';
// import { I18nextProvider } from 'react-i18next';

// React Router
import { BrowserRouter as Router } from 'react-router-dom'
import GA from './services/GoogleAnalytics'

// React Redux
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";

// TODO: How to chuck and minimize this
//import 'vibemap-constants/design-system/semantic/dist/semantic.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/button.css'
import 'vibemap-constants/design-system/semantic/dist/components/dropdown.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/grid.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/header.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/icon.css'

// TODO: Replace with icon.css, custom
import 'vibemap-constants/design-system/semantic/dist/components/input.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/item.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/label.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/menu.min.css';
import 'vibemap-constants/design-system/semantic/dist/components/reset.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/segment.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/text.min.css'

// This is a key step that gets the preloaded inialProps
const preloadedState = getSerializedData('preloaded_state')

const store = configureStore(preloadedState);

//console.log('store with preloaded state: ', store)

function renderApp() {
  ensureReady(routes).then(data =>
    loadableReady(() => {
      hydrate(
        <Provider store={store}>
          <Router>
            {GA.init() && <GA.RouteTracker />}
            {/* App.js replaced by After which preloads initial props */}
            <After data={data} routes={routes} store={store} />
          </Router>
        </Provider>,
        document.getElementById('root')
      )
    })
  )
}

renderApp();

if (module.hot) {
  module.hot.accept('./pages/routes', renderApp);
}

// Register the workbox plugin, https://developers.google.com/web/tools/workbox/modules/workbox-webpack-plugin
//register()
unregister()