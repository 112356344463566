import "isomorphic-fetch";

// Use Thunks with Vibemap service
import VibeMap from '../services/VibeMap'

import {
  place_categories,
  ZOOM_ON_DETAILS
} from 'vibemap-constants/dist/constants.js'

import {
  formatPlaces,
  scorePlaces,
  fuzzyMatch,
  toTitleCase,
  in_neighborhood,
} from 'vibemap-constants/dist/helpers.js'

import {
  getVibes
} from 'vibemap-constants/dist/vibes.js'

import {
  getClusters,
  getDistance,
  getFeatureCollection,
  getFeaturesInBounds,
  getTruncatedFeatures
} from 'vibemap-constants/dist/map.js'

import { isBrowser } from "./reducers";
import {detailsShown} from './reducers/index';

export const addFeature = feature => ({ type: 'ADD_FEATURE', feature })
export const setIsBrowser = isBrowser => ({ type: 'SET_IS_BROWSER', isBrowser })
export const setDetailsShown = show => ({ type: 'SET_DETAILS_SHOWN', show })
export const setSavedPlaces = savedPlaces => ({ type: 'SET_SAVED_PLACES', savedPlaces })
export const setShowList = show => ({ type: 'SET_SHOW_LIST', show })
export const setDetailsId = id => ({ type: 'SET_DETAILS_ID', id })
export const setDetailsType = place_type => ({ type: 'SET_DETAILS_TYPE', place_type })
export const setGuideDetails = details => ({ type: 'SET_GUIDE_DETAILS', details })
export const setGuideMarkers = markers => ({ type: 'SET_GUIDE_MARKERS', markers })
export const activateGeod = geod => ({ type: 'ACTIVATE_GEOD', geod })
export const setHeaderSize = size => ({ type: 'SET_HEADER_SIZE', size })
export const setLayers = layers => ({ type: 'SET_LAYERS', layers })
export const setMessage = message => ({ type: 'SET_MESSAGE', message })
export const setShowMessage = showMessage => ({ type: 'SET_SHOW_MESSAGE', showMessage })
export const setShowSignUp = showSignUp => ({ type: 'SET_SHOW_SIGNUP', showSignUp })
export const setEventLocation = location => ({ type: 'SET_EVENT_LOCATION', location })
export const setEventsData = events_data => ({ type: 'SET_EVENTS_DATA', events_data })
export const setEventsGeoJSON = events_geojson => ({ type: 'SET_EVENTS_GEOJSON', events_geojson })

export const setCities = cities => ({ type: 'SET_CITIES', cities })
export const setName = name => ({ type: 'SET_NAME', name })
export const setNearbyPlaces = places => ({ type: 'SET_NEARBY_PLACES', places })
export const setNeighborhoods = cities => ({ type: 'SET_NEIGHBORHOODS', cities })
export const setPageSEO = seo => ({ type: 'SET_PAGE_SEO', seo})
export const setPlacesData = (places_data, refreshResults) => ({ type: 'SET_PLACES_DATA', places_data, refreshResults })
export const setPlacesGeoJSON = places_geojson => ({ type: 'SET_PLACES_GEOJSON', places_geojson })

// Nav reducers
export const setSearchResults = results => ({ type: 'SET_SEARCH_RESULTS', results })

export const setRecommendations = (recommendations) => ({ type: 'SET_RECOMMENDATIONS', recommendations })
export const setWindowSize = size => ({ type: 'SET_WINDOW_SIZE', size })

export const setTopPicks = (places_data, refreshResults, mergeTopPicks) => ({
  type: 'SET_TOP_PICKS_DATA',
  places_data,
  refreshResults,
  mergeTopPicks
})

// Map Actions
// Reducers are in map.reducers
export const setBearing = bearing => ({ type: 'SET_BEARING', bearing })
export const setBounds = bounds => ({ type: 'SET_BOUNDS', bounds })
export const setBoundsReady = boundsReady => ({ type: 'SET_BOUNDS_READY', boundsReady })
export const setDensityBonus = densityBonus => ({ type: 'SET_DENSITY_BONUS', densityBonus })
export const setDistance = distance => ({ type: 'SET_DISTANCE', distance })
export const setLayersChanged = changed => ({ type: 'SET_LAYERS_CHANGED', changed })
export const setLoadMap = loadMap => ({ type: 'SET_LOAD_MAP', loadMap })
export const setMapReady = mapReady => ({ type: 'SET_MAP_READY', mapReady })
export const setMapSize = mapSize => ({ type: 'SET_MAP_SIZE', mapSize })
export const setPixelDistance = pixelDistance => ({ type: 'SET_PIXEL_DISTANCE', pixelDistance })
export const setViewport = viewport => ({ type: 'SET_VIEWPORT', viewport })
export const setZoom = zoom => ({ type: 'SET_ZOOM', zoom })

// Navigation Actions
// Reducers are in nav.reducers
export const setAllCategories = allCategories => ({ type: 'SET_ALL_CATEGORIES', allCategories })
export const setAllCities = allCities => ({ type: "SET_ALL_CITIES", allCities });
export const setAllVibes = allVibes => ({ type: 'SET_ALL_VIBES', allVibes })
export const setActivity = activity => ({ type: 'SET_ACTIVITY', activity })
export const setCurrentLocation = location => ({ type: 'SET_CURRENT_LOCATION', location })
export const setHasLocation = hasLocation => ({ type: 'SET_HAS_LOCATION', hasLocation })
export const setCurrentPage = page => ({ type: 'SET_CURRENT_PAGE', page })
export const setDays = days => ({ type: 'SET_DAYS', days })
export const setMainVibe = vibe => ({ type: 'SET_MAIN_VIBE', vibe })
export const setOrdering = ordering => ({ type: 'SET_ORDERING', ordering })
export const setPlaceType = placeType => ({ type: 'SET_PLACE_TYPE', placeType })
export const setSearchTerm = searchTerm => ({ type: 'SET_SEARCH_TERM', searchTerm })
export const setVibes = vibes => ({ type: 'SET_VIBES', vibes })
export const setVibesets = vibesets => ({ type: 'SET_VIBESETS', vibesets })
export const setTopVibes = top_vibes => ({ type: 'SET_TOP_VIBES', top_vibes })
export const setTotalPages = pages => ({ type: 'SET_TOTAL_PAGES', pages })

export const citiesError = () => ({ type: "CITIES_ERROR" });

// Nav Thunks
export const fetchCategories = () => {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      VibeMap.getCategories().then(results => {
        dispatch(setAllCategories(results.data['place_categories']))
        resolve(results)
      })
    })
  }
}

export const fetchCities = () => (dispatch, getState) => {
  return new Promise(resolve => {
    VibeMap.getCities()
      .then(response => response.data)
      .then(cities => {
        dispatch(setAllCities(cities))
        resolve(cities)
      })
      .catch(err => dispatch(citiesError(err)))
  })
}

// Place Actions
export const detailsRequest = () => ({ type: "DETAILS_LOADING" });
export const detailsReceived = details => ({ type: "DETAILS_SUCCESS", payload: details });
export const detailsError = () => ({ type: "FETCH_DETAILS_FAILURE" });
export const setCurrentItem = place => ({ type: 'SET_CURRENT_ITEM', place })
export const setDetailsLoading = detailsLoading => ({ type: "SET_DETAILS_LOADING", detailsLoading });

export const setPlacesLoading = placesLoading => ({ type: 'SET_PLACES_LOADING', placesLoading })
export const setPlacesError = error => ({ type: 'SET_PLACES_ERROR', error })

// Get Place Details
export const fetchDetails = (id, type) => (dispatch, getState) => {
  return new Promise(resolve => {
    //dispatch(detailsRequest())
    dispatch(setDetailsLoading(true))

    VibeMap.getPlaceDetails(id, type)
      .then(response => response.data)
      .then(details => {
        resolve(details)

        console.log('DISPATCH DETAILS RECIEVED: ', details.properties)
        //console.log("nearest neighborhood: ", nearest_neighborhood(details))
        let test_locations = in_neighborhood(details)

        // Test neighborhood and badge association functions
        dispatch(detailsReceived(details))
        dispatch(setDetailsLoading(false))

      })
      .catch(err => dispatch(detailsError(err)))
  })
}

// Set the current place or event id and update the map and nav state
export const setDetails = (id, type) => (dispatch, getState) => {
    let { viewport, zoom } = getState().map
    console.log('Reducer setDetails: ', id, type)
    if (type === undefined) type = 'places'

    if (zoom < 12) zoom = 12

    let newViewport = Object.assign({}, viewport);
    newViewport.zoom = zoom
    dispatch(setZoom( zoom + ZOOM_ON_DETAILS ))
    dispatch(setViewport( viewport ))
    dispatch(setDetailsId(id))
    dispatch(setDetailsType(type))
    dispatch(setDetailsShown(true))

    if (type) dispatch(setDetailsType(type))

}

export const setLocation = (location) => (dispatch, getState) => {
  const { viewport, zoom } = getState().map

  if (viewport !== 'undefined') {
    let newViewport = Object.assign({}, viewport);
    newViewport.latitude = parseFloat(location.latitude)
    newViewport.longitude = parseFloat(location.longitude)

    dispatch(setViewport(newViewport))

    if (location.zoom) {
      newViewport.zoom = parseFloat(location.zoom)
      dispatch(setZoom(newViewport.zoom))
    }

  } else {
    console.log('No viewport: ', viewport, location)
  }

  dispatch(setCurrentLocation(location))
  dispatch(setHasLocation(true))

}

export const clearDetails = (zoomOut) => (dispatch, getState) => {
  const { detailsShown } = getState()
  const { zoom } = getState().map

  // If zoomed to details zoom out
  if (detailsShown) zoomOut = true

  if (zoomOut) dispatch(setZoom( zoom - ZOOM_ON_DETAILS ))

  dispatch(setDetailsType(null))
  dispatch(setDetailsShown(false))
  dispatch(setDetailsId(null))

}

// Set the current place or event id and update the map and nav state
export const handleSearch = (value) => (dispatch, getState) => {

  const { allCategories, allCities, allVibes, currentLocation, vibesets } = getState().nav

  // Filter categories, vibes, etc. by the search value
  // Result are parameratized

  //const filteredCategories = helpers.filterList(allCategories, value)
  dispatch(setSearchTerm(value))

  // Make array unique and filter array and return an object
  console.log('All categories: ', allCategories, place_categories)
  const filteredCategories = fuzzyMatch(place_categories, value, 'text')
    .map(category => {
      return {
        title : category.text,
        category: 'category',
        key: category.key
      }
    })


  const filteredVibes = fuzzyMatch(allVibes, value)
    .map(vibe => {
      return {
        title : toTitleCase(vibe),
        category: 'vibe',
        key: vibe
      }
    })


  let geoResults = fuzzyMatch(allCities, value, 'name')
    .map(function (item) {
      // Attributes for dropdown
      item['key'] = item['id']
      item['value'] = item['id']
      item['text'] = item['name']
      item['title'] = item['name']
      item['category'] = 'location'

      delete item['description']
      return item
  })

  const seeAll = [{
    title: 'Search for ' + value,
    key: value,
    category: 'all'
  }]

  let allResults = {
    "search": {
      "name": "Everything",
      "results": seeAll
    }
  }

  if (filteredCategories.length > 0) allResults['categories'] = {
    "name" : "Activities",
    "results" : filteredCategories
  }

  if (filteredVibes.length > 0) allResults['vibes'] = {
    "name" : "Vibes",
    "results" : filteredVibes
  }

  if (geoResults.length > 0) allResults['places'] = {
    "name" : "Location",
    "results" : geoResults
  }

  dispatch(setSearchResults(allResults))

  VibeMap.geocode(value)
    .then(results => {
      const options = results.map(result => {
        // Only show direct matches
        // TODO: Make the matches fuzzy
        result.title = result.text
        result.category = 'location'
        // If nearby or direct match
        // TODO: calculate distance
        let distance_between = getDistance(result.centerpoint, [currentLocation.latitude, currentLocation.longitude], 'miles')
        //console.log('Distance : ', result.centerpoint, currentLocation, distance_between)
        if (result.text.includes(value)) {
          geoResults.push(result)
        } else if (distance_between <= 100) {
          geoResults.push(result)
        }

        return result
      })
      return options
    })
    .then(results => {
      console.log('geocoding result: ', geoResults)

      if (geoResults.length > 0) allResults['places'] = {
        "name" : "Location",
        "results" : geoResults
      }

      dispatch(setSearchResults(allResults))
    })

  //console.log('Search for this: ', value, allResults)
  //dispatch(setSearchResults(allResults))

}

// Set the current place or event id and update the map and nav state
export const handleVibeset = (value) => (dispatch, getState) => {
  const { vibesets } = getState().nav


  if (value === '' || value === 'all') {
    dispatch(setMainVibe(null))
    dispatch(setVibes([]))
  }

  let vibes = []

  if (value && value !== '' && value !== 'all') {
    const current = vibesets.find(({ key }) => key === value);

    if (current) {
      console.log('handleVibeset: ', value, vibesets, current)
      dispatch(setMainVibe(value))

      if (current !== undefined || current !== null) vibes = current.vibes

      //this.setState({ vibes: vibes })
      //this.props.setVibes(vibes)
      dispatch(setVibes(vibes))
    }
  }
}

// Dispatch is called in getInitialProps of Details
// args:
export const fetchPlaces = (options, refreshResults) => (dispatch, getState) => {

  const { savedPlaces } = getState()
  //const {mainVibe} = getState().nav

  let { vibes, search, zoom, mainVibe } = options

  const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)

  dispatch(setPlacesLoading(true))

  const should_search = vibes.length > 0 || search !== ""

  // Vibemap service handles the logic and data wranggling
  // This module shoudl just get and set
  return new Promise(resolve => {

    // Either do a specific Search
    if (should_search) {
      // Search for top picks...
      VibeMap.getPicks(options)
        .then(response => {
          const results = response.data

          // Set Data with minor side effects
          if (results && results.length > 0) {
            // Set places in he results
            console.log('setTopPlaces: ', refreshResults)
            dispatch(setTopPlaces(results, refreshResults))
            dispatch(setPlacesLoading(false))
          } else {
            // TODO: Add dispatch for API errors
            console.log('Error or no results. See in Redux state', results)
          }
        }, (error) => {
          console.log(error)
        })
    }

    // Or get general places
    VibeMap.getPlaces(options)
      .then(response => {
        const results = response.data

        if (results && results.length > 0) {

          // Set Data with minor side effects
          // Like check against local storage for saved places.
          let withSavedPlaces = results.map((place) => {
            const foundIndex = savedPlaces.findIndex(obj => obj.id === place.id)
            place.properties.is_saved = (foundIndex > -1) ? true : false
            return place
          })

          dispatch(setPlacesData(withSavedPlaces, refreshResults))
          dispatch(setDensityBonus(response.density_bonus))
          dispatch(setPlacesLoading(false))

          const places_data = withSavedPlaces.map(place => {
            delete place.properties.data_sources
            delete place.properties.hotspots_events

            return place
          })

          let places_geojson = getFeatureCollection(places_data)

          // Truncate long coordinates
          places_geojson = getTruncatedFeatures(places_geojson)

          dispatch(setPlacesGeoJSON(places_geojson))

          if (should_search === false) dispatch(setTopPlaces(results, true))

        } else {
          console.log('Error or no results. See in Redux state', response)
        }

        // Return to component promise
        resolve(results)
      })
      .catch(err => {
        console.log(err)
      })
  })
}

// Dispatch is called in getInitialProps of Details
// args:
export const fetchEvents = (options, refreshResults) => (dispatch, getState) => {

  const { savedPlaces } = getState()

  let { vibes, search, zoom, mainVibe } = options
  const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)

  dispatch(setPlacesLoading(true))

  const should_search = vibes.length > 0 || search !== ""

  // Vibemap service handles the logic and data wranggling
  // This module should just get and set
  return new Promise(resolve => {

    // Or get general places
    VibeMap.getEvents(options)
      .then(response => {
        const results = response.data

        if (results && results.length > 0) {

          // Set Data with minor side effects
          /* Like check against local storage for saved places.
          let withSavedPlaces = results.map((place) => {
            const foundIndex = savedPlaces.findIndex(obj => obj.id === place.id)
            place.properties.is_saved = (foundIndex > -1) ? true : false
            return place
          })
          */

          dispatch(setEventsData(results, refreshResults))
          dispatch(setDensityBonus(response.density_bonus))
          dispatch(setPlacesLoading(false))

          let events_geojson = getFeatureCollection(results)
          dispatch(setEventsGeoJSON(events_geojson))

          if (should_search === false) dispatch(setTopPicks(results, refreshResults))

        } else {
          console.log('Error or no results. See in Redux state', response)
        }

        // Return to component promise
        resolve(results)
      })
      .catch(err => {
        console.log(err)
      })
  })
}

// Function called upon when you load onto new page
export const setTopPlaces = (results, refreshResults) => (dispatch, getState) =>{

  // Handle Pagination from State
  const { currentPage, numTopPicks, clusterSize } = getState().nav

  // If there are less results than results_per_page (numTopPicks), set default page number to 1
  // Also referenced in Main.js pageTopPicks (when you change pages)
  const num_results = results.length
  const num_pages = num_results <= numTopPicks ? 1 : Math.ceil(num_results / numTopPicks)
  const first = (currentPage - 1) * numTopPicks

  // If not on last page, add numTopPicks to last (20).
  // Otherwise, if multiple of numTopPicks, add numTopPicks. If not, add remainder
  const last = (
    (currentPage !== num_pages) ? first + numTopPicks :
    (currentPage == num_pages && (num_results%numTopPicks) !== 0) ? first + (num_results%numTopPicks)  :
    (currentPage == num_pages && (num_results%numTopPicks) == 0) ? first + numTopPicks  : 0
  )
  const top_picks = results.splice(first, last)

  //console.log(currentPage, num_results, numTopPicks, num_pages)
  const top_picks_clustered = getClusters(top_picks, clusterSize)

  // TODO: dynamic value for merge top
  dispatch(setTopPicks(top_picks_clustered, refreshResults))
  dispatch(setTotalPages(num_pages))

}

export const handleSavedPlace = (place) => (dispatch, getState) => {

  return new Promise(resolve => {
    const { savedPlaces, topPicks } = getState()

    const foundIndex = savedPlaces.findIndex(obj => obj.id === place.id)
    let isSaved = (foundIndex > -1) ? true : false

    let updateSavedPlace = savedPlaces
    let updateTopPicks = topPicks

    if (isSaved) {
      updateSavedPlace.splice(foundIndex, 1)
      console.log('REMOVED item from saved places', savedPlaces)
    } else {
      // TODO: update top picks?
      console.log('Should update top picks? ', topPicks)
      updateSavedPlace.push(place)
    }

    dispatch(setSavedPlaces(updateSavedPlace))

    const foundInPicks = updateTopPicks.findIndex(obj => obj.id === place.id)
    if (foundInPicks > -1) {
      updateTopPicks[foundInPicks].properties.is_saved = !isSaved

      console.log('Found in Top Picks: ', isSaved, updateTopPicks[foundInPicks])

      dispatch(setTopPicks(updateTopPicks))

    }

    resolve(isSaved)

  })
}

export const updatePlacesFromNav = (type) => (dispatch, getState) => {
  const { activity, vibes, ordering } = getState().nav

}

export const placesFromTile = (features, type, bounds) => (dispatch, getState) => {

  const MIN_NUM_VIBES = 2
  const { currentLocation, activity, mainVibe, vibes, ordering, searchTerm } = getState().nav
  const { zoom } = getState().map
  const scoreBy = ['aggregate_rating', 'categories', 'vibes', 'distance', 'offers', 'hours']
  const center_point = [currentLocation.longitude, currentLocation.latitude]

  const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)
  //console.log("mainVibe here - mainVibe, "Vibes here:", vibes, "all_vibes: ", all_vibes)

  // TODO: Skip or filter client side?
  //console.log('placesFromTile: ', searchTerm, isSearching)
  const isSearching = searchTerm.length > 0
  if (isSearching) return true

  //const featuresFromTile = Object.assign({}, features)
  //const featuresFromTile = [...features];

  // TODO: Handle errors or empty results from Tile Server
  //let points = features.map(feature => feature.properties.id)
  //let points_uniq = uniq(points)
  //console.log('Parsing this many places: ', points.length, points_uniq.length)
  //console.log('Raw places from tile server ', features)

  const features_in_bounds = getFeaturesInBounds(features, bounds)

  const places_decoded = features_in_bounds.map((feature) => {

    if (feature.id === undefined) feature.id = feature.properties.id
    feature.properties.vibes = JSON.parse(feature.properties.vibes)
    feature.properties.num_vibes = feature.properties.vibes.length
    //feature.properties.tips = JSON.parse(feature.properties.tips)
    feature.properties.subcategories = JSON.parse(feature.properties.subcategories)
    feature.properties.categories = JSON.parse(feature.properties.categories)
    //console.log('feature.properties: ', feature.properties.thumbnail_url)
    feature.properties.images = [feature.properties.thumbnail_url]

    if (feature.properties.opening_hours != undefined) feature.properties.opening_hours = JSON.parse(feature.properties.opening_hours)

    delete feature.properties.tips
    //delete feature.properties.subcategories
    delete feature.properties.facebook
    delete feature.properties.telephone
    delete feature.properties.website

    //delete feature['_vectorTileFeature']

    // TODO: fix uncategories spots
    if (feature.properties.categories.length < 1 && feature.properties.vibes.length > 2) {
      //console.log('place missing category: ', feature.properties.name, feature.properties)
    }
    // TODO: Fix drinking spots
    //if (feature.properties.categories.includes('drink')) console.log('fix this category category: ', feature.properties.name, feature.properties.categories)

    return feature
  })

  const places_decoded_filtered = places_decoded.filter(place => place.properties.num_vibes > MIN_NUM_VIBES)

  const places_formatted = formatPlaces(places_decoded_filtered)

  // Filter based on categories
  // TODO: This will become a stored procedure in Postgres before too long
  const places_filtered_category = places_formatted.filter(function (place) {
    return (activity === 'all' || place.properties.categories.includes(activity))
  })

  // Filter based on vibes
  /*
  const places_filtered_vibe = places_filtered_category.filter(function (place) {
    return (vibes.length === 0 || helpers.matchLists(place.properties.vibes, vibes) > 0)
  })
  */

  const places_scored_and_sorted = scorePlaces(places_filtered_category, center_point, all_vibes, scoreBy, ordering, zoom)

  // TODO: Move this method for finding duplicates to ETL
  // let names = places_scored_and_sorted.map(place => place.properties.name)
  // let findDuplicates = names.filter((item, index) => names.indexOf(item) != index)
  // console.log('Duplicate names: ', findDuplicates)

  /* Tests for Nearby Sorting
  let test_nearest_places = nearest_places(places_filtered_category, center_point)
  console.log("All Nearby Places (0.1): ", test_nearest_places)
  */


  dispatch(setPlacesData(places_scored_and_sorted, true))
  dispatch(setTopPlaces(places_scored_and_sorted, true))

  // FIXME: Remove this is just a temporary work around to get the JSON to work with Mapbox
  let places_data = places_scored_and_sorted.map(place => {
    delete place.properties.data_sources
    delete place.properties.hotspots_events

    return place
  })

  if (places_data.length > 0) {
    let places_geojson = getFeatureCollection(places_data)
    // Truncate long coordinates
    places_geojson = getTruncatedFeatures(places_geojson)

    //console.log(JSON.stringify(places_geojson))
    dispatch(setPlacesGeoJSON(places_geojson))
  }

  dispatch(setPlacesLoading(false))

}

export const fetchVibes = () => {
  return (dispatch, getState) => {
    return new Promise(resolve => {

      VibeMap.getVibes().then(results => {
        //return console.log('fetchVibes...', results)
        dispatch(setVibesets(results.data['signature_vibes']))
        const allVibes = getVibes()
        dispatch(setAllVibes(allVibes))
        resolve(results)
      })
    })
  }
}

// Dispatch is called in getInitialProps of Details
export const getDetails = (id, type) => {

  return new Promise(resolve => {
    VibeMap.getPlaceDetails(id, type)
      .then(response => response.data)
      .then(details => resolve(details))
      .catch(err => console.log('Error fetching details'))

  })
}
