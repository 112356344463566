import React from 'react';
import { useHistory } from 'react-router-dom';

import { Redirect } from "react-router-dom";

const RedirectWithParams = () => {
    const history = useHistory();

    return (
        <Redirect to={'discover/' + history.location.search} push={history.push} />
    );
}

export default RedirectWithParams;