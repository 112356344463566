import React from 'react';

import Redirect from "./Redirect";

// Handles prefetching of async data
import { asyncComponent } from '@jaredpalmer/after';

const routes = [
	{
		path: "/",
		exact: true,
		component: Redirect
	},
	{
		path: "/discover/:city?",
		exact: true,
		component: asyncComponent({
			loader: () => import('./Main'), // required
			Placeholder: () => <div>...LOADING...</div>
		})
	},
	{
		path: "/gradient",
		component: asyncComponent({
			loader: () => import('./Gradient'), // required
			Placeholder: () => <div>...LOADING...</div>
		})
	},
	{
		path: "/details/:id",
		exact: true,
		component: asyncComponent({
			loader: () => import('./Details'), // required
			Placeholder: () => <div>...LOADING...</div>
		}),

	},
	{
		path: "/events/:id",
		exact: true,
		component: asyncComponent({
			loader: () => import('./EventDetails'), // required
			Placeholder: () => <div>...LOADING...</div>
		}),

	}

];

export default routes;