const querystring = require('querystring')
const truncate = require('truncate')

//const fetch = require('node-fetch');
//global.Headers = fetch.Headers;
require('isomorphic-fetch');

const Constants = require('vibemap-constants/dist/constants.js')
const helpers = require("vibemap-constants/dist/helpers.js")
const map = require("vibemap-constants/dist/map.js")

const { MAPBOX_TOKEN } = process.env

// TODO: tie this value to the DEV or PROD var
//const devAPI = 'http://143.198.100.9'
const ApiUrl = 'https://api.vibemap.com'

const mapbox_url = 'https://api.mapbox.com/datasets/v1/stevepepple/'

let timedOut = false

let timeout = setTimeout(() => {
    timedOut = true
}, Constants.TIMEOUT)

module.exports = {
    doSomething(arg) {
        return new Promise(function (resolve, reject) {
            resolve(true)
        })
    },

    addVibe: function(id, vibe, time = null, day = null) {
        const now = new Date()
        if (!time) time = now.getHours()

        const requestOptions = {
            method: 'POST',
            authorize: true,
            headers: {
                'Content-Type': 'application/json'
             },
            body: JSON.stringify({
                'hotspots_place': id,
                'vibe': vibe,
                'time_of_day': time,
                'day_of_week': day,
            })
        }

        return new Promise(function (resolve, reject) {
            fetch(ApiUrl + '/v0.3/user-vibe-times/', requestOptions)
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout);
                    resolve({ data: res.results, loading: false, timedOut: false })
                }, (error) => {
                    console.log(error)
                });
        });
    },

    // TODO: Replace by reusable-method
    geocode: (address, location) => {
        return new Promise((resolve, reject) => {
            map.geocodeAddress(address)
                .then(results => {
                    // TODO: This is probably not the right way to do this
                    let new_locations = results.map(address => {
                        if (address.formatted_address) {

                            return { key: address.place_id, id: address.place_id, text: address.formatted_address, centerpoint: [address.geometry.location.lat(), address.geometry.location.lng()], value: address.formatted_address }
                        } else {
                            return []
                        }
                    })

                    resolve(new_locations)
                })
        })

    },

    getCities: function() {
        return new Promise(function (resolve, reject) {
            fetch(ApiUrl + '/v0.3/boundaries/')
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout);
                    resolve({ data: res.results, loading: false, timedOut: false })
                }, (error) => {
                    console.log(error)
                });
        });
    },

    getCategories: function () {
        return new Promise(function (resolve, reject) {

            fetch(ApiUrl + "/v0.3/category-list/")
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout)
                    resolve({ data: res, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        });
    },

    getDirections: function(waypoints) {
        return new Promise(function (resolve, reject) {
            const service = 'https://api.mapbox.com/directions/v5/mapbox/walking/'
            let query = querystring.stringify({
                access_token: MAPBOX_TOKEN,
                geometries: 'geojson',
                steps: true,
                waypoints: []
            })

            const start = waypoints[0]
            const end = waypoints[waypoints.length - 1]

            let start_end = String(start) + ';' + String(end)
            //if (waypoints !== undefined) query['waypoints'] = query += 'waypoints=' + waypoints.join(';')

            start_end = waypoints.join(';')
            console.log('Getting directions for ', start_end, query)

            fetch(service + start_end + "?" + query)
                .then(data => data.json())
                .then(res => {
                    console.log('Got Directions: ', res)
                    clearTimeout(timeout)
                    resolve({ data: res, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
            })
    },

    getStories: function (placeId = '11edfd6b-464a-4638-81bc-6a06c9fa4aa5') {
        return new Promise(function (resolve, reject) {

            fetch('https://cms.vibemap.com/wp-json/wp/v2/posts?place_ids='+ placeId)
                .then(data => data.json())
                .then(res => {
                    //console.log('Got stories resopnse: ', res)
                    clearTimeout(timeout)
                    resolve({ data: res, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        });
    },

    getVibes: function () {
        return new Promise(function (resolve, reject) {
            let query = querystring.stringify({
                // lat: this.state.lat,
                // lon: this.state.lon,
                //point: point,
                // distance: this.state.distance,
                //dist: distanceInMeters,
                //activity: activity,
                //days: days,
            })

            fetch(ApiUrl + "/v0.3/vibe-list/?" + query)
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout)
                    resolve({ data: res, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                })
        });
    },

    getTopVibes: function(places){

        let top_vibes = {}

        places.map((place) => {
            place.properties.vibes.map((vibe) => {
                if (top_vibes.hasOwnProperty(vibe)) {
                    top_vibes[vibe] += 1
                } else {
                    top_vibes[vibe] = 1
                }
                return null
            })
            return null
        })

        var sortable = [];
        for (var vibe in top_vibes) {
            sortable.push([vibe, top_vibes[vibe]]);
        }

        let top_vibes_sorted = sortable.sort(function (a, b) { return b[1] - a[1] });

        return top_vibes_sorted

    },

    getGuides: function(){
        return new Promise(function (resolve, reject) {

        })
    },

    getNeighborhoods: function(){
        return new Promise(function (resolve, reject) {
            let query = querystring.stringify({
                access_token : 'pk.eyJ1Ijoic3RldmVwZXBwbGUiLCJhIjoiTmd4T0wyNCJ9.1-jWg2J5XmFfnBAhyrORmw'
            })

            fetch(mapbox_url + "ck2v5hz4r1md12nqnfff9592e/features/?" + query)
                .then(data => data.json())
                .then(res => {

                    clearTimeout(timeout);
                    resolve({ data: res, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        });
    },

    getEventDetails: function(id){
        return new Promise(function (resolve, reject) {
            fetch(ApiUrl + "/v0.3/events/" + id)
                .then(data => data.json())
                .then(result => {
                    clearTimeout(timeout);
                    resolve({ data: result, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                })
        })
    },

    // TODO: Include a way to query by time of day
    getPlaceDetails: function (id, type) {
        console.log('Get place details: ', id)

        // TODO: Handle Guides type
        if(type === null || type === undefined) type = 'places'
        return new Promise(function (resolve, reject) {
            const endpoint = ApiUrl + "/v0.3/"+ type + "/" + id + "/"
            fetch(endpoint)
                .then(data => data.json())
                .then(result => {

                    //console.log('Got place details!!! ', result)

                    if (result.detail === 'Not found.' || result.id == undefined ) {
                        console.log('Places does not exist')
                        resolve({ data: null, loading: false, timedOut: false })
                    }

                    // TODO: Important need to handle cases where a place is closed or deleted.
                    // Put the id in properties
                    result.properties.id = result.id

                    if (type === 'places') {
                        let { openNow, openToday, isPopular } = helpers.isOpen(result.properties.opening_hours)

                        // Store in place details
                        result.properties.open_now = openNow
                        result.properties.popular_now = isPopular
                    } else {
                        // TODO: Work around for missing fields
                        if (result.properties.tips === undefined ) result.properties.tips = []
                        if (result.properties.vibes === undefined) result.properties.vibes = []
                    }

                    clearTimeout(timeout);
                    resolve({ data: result, loading: false, timedOut: false })

                }, (error) => {
                    console.log(endpoint, error)
                    reject(error)

                })
        })
    },

    getHeatMap: function () {
        let url = 'https://tiles.vibemap.com/maps/places/11/325/793.mvt'

        return new Promise(function (resolve, reject) {
            const ApiHeaders = {
                'Authorization': 'Token ' + Constants.SYSTEM_TOKEN
            }

            fetch(url, { headers: ApiHeaders })
                .then(data => console.log(data))

        })
    },

    getAPIParams(options, per_page = 50) {

        let { activity, distance } = options
        let params = Object.assign({}, options)

        let distanceInMeters = 1
        if (distance > 0) distanceInMeters = Math.round(distance * Constants.METERS_PER_MILE)

        // API currently doesn't support other options
        // However, the sorting algorithm, will use them
        params['ordering'] = '-aggregate_rating'

        // TODO: Load more points at greater distances?
        params['per_page'] = per_page

        // Rename args
        if (activity !== 'all' && activity !== null) params['category'] = activity
        params['dist'] = distanceInMeters
        delete params['activity']
        delete params['distance']
        delete params['bounds']
        //console.log('distanceInMeters', distanceInMeters, params['dist'])

        return params
    },

    // TODO: Include a way to query by time of day
    getPicks: function (options) {
        let {
            activity,
            bounds,
            days,
            distance,
            ordering,
            point,
            search,
            time,
            vibes,
            zoom,
            mainVibe } = options

        // Don't allow distance to be negative.
        let distanceInMeters = 1
        if (distance > 0) distanceInMeters = distance * Constants.METERS_PER_MILE

        if (activity === 'all') activity = null

        const scoreBy = ['aggregate_rating', 'vibes', 'distance', 'offers', 'hours']
        const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)
        // TODO: Load more points at greater distances?
        // TODO: Load fewer points, once scoring is on the backend.
        // TODO: This has moved to helpers in vibemap-constants
        return new Promise(function (resolve, reject) {

            const params = module.exports.getAPIParams(options, 250)

            let centerPoint = point.split(',').map(value => parseFloat(value))
            let query = querystring.stringify(params);

            fetch(ApiUrl + "/v0.3/places/?" + query)
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout);
                    const count = res.count

                    //console.log('getPicks got this many places: ', count)

                    let places = module.exports.formatPlaces(res.results.features)
                    let placesScoredAndSorted = helpers.scorePlaces(places, centerPoint, all_vibes, scoreBy, ordering, zoom)

                    // TODO: clustering could happen before and after identification of picks; for now just do it after
                    //let clustered = module.exports.clusterPlaces(placesScoredAndSorted, 0.2)

                    let top_vibes = module.exports.getTopVibes(places)

                    resolve({ data: placesScoredAndSorted, count: count, top_vibes: top_vibes, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        })
    },

    // TODO: Include a way to query by time of day
    getPlaces: function (options, per_page = 50) {
        let { activity, bounds, days, distance, ordering, point, search, time, vibes, zoom, mainVibe} = options

        let centerPoint = point.split(',').map(value => parseFloat(value))

        const scoreBy = ['aggregate_rating', 'vibes', 'distance', 'offers', 'hours']
        const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)
        if (activity === 'all') activity = null

        // TODO: Fix UTC format problem...
        // let day_start = dayjs().startOf('day').utc().format("YYYY-MM-DD HH:MM")
        // let day_end = dayjs().add(days, 'days').utc().format("YYYY-MM-DD HH:MM")

        // TODO: Load more points at greater distances?
        return new Promise(function (resolve, reject) {

            const params = module.exports.getAPIParams(options, per_page)

            // Retry recursively
            fetchAndRetry()

            function fetchAndRetry(retries = 3) {
                const query = querystring.stringify(params);
                const url = ApiUrl + "/v0.3/places/?" + query

                fetch(url)
                    // Parse JSON
                    .then(data => data.json())
                    // Handle Retries
                    .then(res => {
                        clearTimeout(timeout);

                        // TODO: Make this a util func and move to the backend
                        const count = res.count

                        // TODO: Retry and back off
                        if (count == 0) {
                            // Reduce distance
                            if (retries > 0) {
                                params['dist'] = params['dist'] / 2
                                return fetchAndRetry(url, retries - 1, params['dist'])
                            } else {
                                //throw new Error('No results')
                                resolve({ loading: false, timedOut: true, message: 'No results' })
                            }
                        }

                        // Score, Sort, & Return
                        let area = map.getArea(bounds)
                        let density = count / area

                        let relative_density = helpers.scaleDensityArea(density)
                        let density_bonus = helpers.scaleDensityBonus(relative_density)

                        let places = module.exports.formatPlaces(res.results.features)
                        let placesScoredAndSorted = helpers.scorePlaces(places, centerPoint, all_vibes, scoreBy, ordering, zoom)
                        //let clustered = module.exports.clusterPlaces(placesScoredAndSorted, 0.2)

                        let top_vibes = module.exports.getTopVibes(res.results.features)
                        // TODO: remove this quick way of export the current data results to a map
                        //console.log(JSON.stringify(res))
                        resolve({
                            data: placesScoredAndSorted,
                            density_bonus: density_bonus,
                            count: count,
                            top_vibes: top_vibes,
                            loading: false,
                            timedOut: false
                        })


                    }, (error) => {
                        console.log(error)
                    })
            }

        })
    },

    // TODO: Include a way to query by time of day
    searchPlaces: function (search_term) {

        return new Promise(function (resolve, reject) {
            let params = {
                search: search_term,
                per_page: 20
            }

            let query = querystring.stringify(params);

            fetch(ApiUrl + "/v0.3/places/?" + query)
                .then(data => data.json())
                .then(res => {

                    clearTimeout(timeout);
                    if (res.status > 400) {
                        return this.setState(() => {
                            return { data: null, loading: false, timedOut: false, message: res.status };
                        });
                    }

                    let search_results = res.results.features.map((place) => {
                        let result = {
                            title: place.properties.name,
                            id: place.id,
                            key: place.id,
                            description: place.properties.address,
                            new: false
                        }

                        return result
                    })

                    search_results.push({
                        title : search_term,
                        key: null,
                        description : 'Add a new listing with this name.',
                        new: true
                    })


                    resolve({ data: res.results.features, search_results: search_results, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        })
    },

    getEvents: function (options, per_page=50) {

        let { activity, bounds, days, distance, ordering, point, search, time, vibes, zoom, mainVibe } = options

        let centerPoint = point.split(',').map(value => parseFloat(value))

        let distanceInMeters = distance * Constants.METERS_PER_MILE

        const all_vibes = mainVibe == null ? vibes: vibes.concat(mainVibe)

        return new Promise(function (resolve, reject) {

            const params = module.exports.getAPIParams(options, per_page)
            let query = querystring.stringify(params);

            console.log('get events ', ApiUrl + "/v0.3/events/?" + query)


            fetch(ApiUrl + "/v0.3/events/?" + query)
                .then(data => data.json())
                .then(res => {
                    clearTimeout(timeout)
                    const count = res.count

                    // Apply some temporary scoring to events to make them show up better.
                    let event_places = module.exports.formatEvents(res.results.features)
                    let scored = helpers.scorePlaces(event_places, centerPoint, all_vibes, ['vibes', 'distance', 'likes'], zoom)

                    resolve({ data: scored, count: count, loading: false, timedOut: false })

                }, (error) => {
                    console.log(error)
                });
        })
    },

    formatEvents: function (events) {
        let formatted = events.map((event) => {
            let fields = event.properties

            // Add fields for presentation
            fields.place_type = 'events'
            fields.short_name = truncate(fields.name, Constants.TRUCATE_LENGTH)
            fields.aggregate_rating = parseFloat(fields.aggregate_rating)
            if (fields.categories === undefined || fields.categories.length === 0) {
                fields.categories = ["missing"]
            }

            if (fields.vibes === undefined) fields.vibes = ['chill']

            fields.cluster = null
            // TODO: why is this needed for icon points
            fields.id = event.id

            event.properties = fields
            return event
        })
        return formatted
    },

    formatPlaces: function(places) {
        const formatted = places.map((place) => {
            let fields = place.properties

            // Add fields for presentation
            fields.place_type = 'places'
            fields.short_name = truncate(fields.name, Constants.TRUCATE_LENGTH)
            fields.aggregate_rating = parseFloat(fields.aggregate_rating)

            fields.sub_categories = fields.sub_categories
            fields.top_vibe = null

            if (fields.categories === undefined || fields.categories.length === 0) {

                fields.categories = ["missing"]
                //if (fields.aggregate_rating > 4) console.log('Missing category: ', fields.name, fields.sub_categories, mainCategory, fields.aggregate_rating)
            }

            fields.icon = fields.categories[0]

            fields.cluster = null

            // TODO: why is this needed for icon points
            //fields.id = place.id
            //console.log('formatPlaces: ', place.id, fields.id)

            place.properties = fields

            return place
        })
        return formatted
    }

}
